<template>
    <div id="page-api">
        <app-loader v-if="loading" fixed-on="desktop"></app-loader>

        <api-header />

        <div id="redoc-container" ref="redoc-container"></div>

        <api-footer />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'

import apiHeader from './components/api-header'
import apiFooter from './components/api-footer'

import isTouchDevice from '@/helpers/is-touch-device'

export default {
    components: {
        appLoader,

        apiHeader,
        apiFooter,
    },

    data() {
        return {
            loading: false,
        }
    },

    mounted() {
        let script = document.createElement('script')
            script.src = 'https://cdn.jsdelivr.net/npm/redoc@next/bundles/redoc.standalone.js'
            script.onreadystatechange = script.onload = () => {
                if (!script.readyState || /loaded|complete/.test(script.readyState)) {
                    Redoc.init(this.spec_url, {}, this.$refs['redoc-container'], this.onRedocLoaded)
                }
            }

        document.getElementsByTagName('head')[0].appendChild(script)
    },

    methods: {
        onRedocLoaded(error) {
            if (error) {
                console.error(error)
            } else {
                const download_button = document.querySelector('.api-info a')

                download_button.href = this.pdf_url
                download_button.removeAttribute('target')

                if (!this.is_touch_device) {
                    download_button.addEventListener('click', this.onDownloadButtonClick)
                }
            }
        },

        onDownloadButtonClick(event) {
            event.preventDefault()

            event.target.setAttribute('disabled', 'disabled')

            // The number of milliseconds to wait
            const delay = 1000

            const timeout = setTimeout(() => {
                this.loading = true
            }, delay)

            const xhr = new XMLHttpRequest()

            xhr.open('GET', this.pdf_url)

            // Set the reponse type to blob since that's what we're expecting back
            xhr.responseType = 'blob'

            xhr.send()

            xhr.onload = () => {
                if (xhr.status == 200) {
                    // Create a new Blob object using the response data of the onload object
                    const blob = new Blob([ xhr.response ], { type: 'image/pdf' })

                    // Create a DOMString representing the blob
                    const url = window.URL.createObjectURL(blob)

                    // Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
                    const a = document.createElement('a')
                    a.style = 'display: none'
                    document.body.appendChild(a)
                    a.href = url
                    a.download = 'api.pdf'

                    // Programatically click the link to trigger the download
                    a.click()

                    // Release the reference to the file by revoking the Object URL
                    window.URL.revokeObjectURL(url)
                }

                clearTimeout(timeout)

                this.loading = false

                event.target.removeAttribute('disabled')
            }
        },
    },

    computed: {
        ...mapGetters([
            'api_docs_host',
        ]),

        is_touch_device() {
            return isTouchDevice()
        },

        spec_url() {
            return this.api_docs_host + '/api/swagger.json'
        },

        pdf_url() {
            return this.api_docs_host + '/api/docs/pdf/download'
        },
    },
}
</script>

<style lang="scss">
@mixin api-tag($color, $line-height) {
    display: inline-block;
    white-space: nowrap;
    color: $color;
    line-height: $line-height;
    margin: 2px 4px;
    padding: 0 8px;
    border: none;
    border-radius: 2px;
    background: rgba(#ccc, 0.4);
    box-shadow: 0 0 0 1px #ccc;
}

@mixin api-auth-ul($font-size, $line-height) {
    margin-top: 16px;
    padding-left: 0;

    &:last-child {
        margin-bottom: 0;
    }

    li {
        display: flex;
        justify-content: flex;
        align-items: flex-start;
        font-size: $font-size;
        line-height: $line-height;

        &:before {
            flex-shrink: 0;
            content: "";
            display: inline-block;
            width: 8px;
            height: 8px;
            margin: #{ ($line-height - 8px) / 2 } 8px 0 0;
            border-radius: 50%;
            background: #e60000;
        }
    }
}

#page-api {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    background: #fff;

    .app-loader {
        position: fixed;
    }

    .api-header,
    .api-footer {
        flex-shrink: 0;
    }

    #redoc-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        &>div[style]:only-child {
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            &>div {
                display: none;
            }

            svg {
                display: none;
            }

            &:after {
                content: "";
                display: block;
                width: 100px;
                height: 100px;
                margin-bottom: 120px;
                background: url(./images/loader.svg) no-repeat center;
                background-size: contain;
            }
        }

        .redoc-wrap {
            $menu-width: 390px;

            $api-content-width: .6;
            $api-samples-width: 1 - $api-content-width;

            $bg-samples: #666;

            font-family: inherit;

            * {
                font-family: inherit;
            }

            .menu-content {
                width: 390px;
                background: #f4f4f4;
                
                [role=search] {
                    $search-height: 50px;
                    
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    margin: 0 24px;
                    padding: 0;
                    border: none;
                    position: relative;

                    @include icon-before($icon-search);

                    &:before {
                        height: $search-height;
                        color: #6c6c6c;
                        line-height: $search-height;
                        margin: 0 4px 0 -4px;
                        position: absolute;
                    }

                    &>i {
                        flex-shrink: 0;
                        width: 16px;
                        right: 0;
                        font-size: 20px;
                        line-height: $search-height;
                        margin: 0;
                    }

                    svg {
                        display: none;
                    }

                    input {
                        flex-grow: 1;
                        height: $search-height;
                        font-weight: 300;
                        font-size: 16px;
                        padding: 0 24px;
                        border: 0px solid #ccc;
                        border-bottom-width: 1px;

                        &::placeholder {
                            color: #999;
                            font-weight: 300;
                        }

                        &+div {
                            width: calc(100% + 24px * 2);
                            margin: 16px -24px 0;
                            background: rgba(#d2d2d2, .2);

                            [data-role="search:results"] {
                                margin: 0;
                                padding: 0;
                                background: transparent;
                            }
                        }
                    }
                }

                ul[role=navigation],
                [data-role="search:results"] {
                    padding: 12px 0;

                    li {
                        font-size: 18px;
                        line-height: 22px;
                        margin: 0;

                        &:hover {
                            background: transparent;
                        }

                        label[role=menuitem] {
                            padding: 8px 24px;
                            line-height: 26px;

                            &:hover {
                                background: rgba(#d2d2d2, .2);
                            }

                            &.active {
                                background: rgba(#d2d2d2, .4);
                            }

                            &[type] {
                                font-size: 16px;
                            }

                            &[type=group] {
                                margin-top: 16px;
                            }

                            &[type=tag] {
                                font-size: 18px;
                                font-weight: bold;
                                margin: 8px 0 0 0;
                                background: transparent;

                                &.active {
                                    color: #333;
                                }

                                svg {
                                    height: 22px;
                                }
                            }

                            span {
                                &[type] {
                                    margin: 0 8px 0 0;
                                }
                            }
                        }

                        ul {
                            li {
                                ul {
                                    li {
                                        label[role=menuitem] {
                                            padding: 6px 24px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &+div {
                    right: 15px;
                    bottom: 15px;
                    width: 64px;
                    height: 64px;
                    padding: 0 22px;
                    background: #fff;
                    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25);

                    &>div {
                        height: 32px;

                        svg {
                            path {
                                fill: #f91600;
                            }

                            &:first-child {
                                transform: translate(2px, -4px) rotate(180deg);
                            }

                            &:last-child {
                                transform: translate(2px, 4px);
                            }
                        }
                    }
                }

                &[open] {
                    &+div {
                        &>div {
                            svg {
                                &:first-child {
                                    transform: translate(2px, 11px) rotate(180deg) !important;
                                }

                                &:last-child {
                                    transform: translate(2px, -10px) !important;
                                }
                            }
                        }
                    }
                }
            }
            
            span {
                &[type] {
                    flex-shrink: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 69px;
                    height: 26px;
                    font-size: 14px;
                    font-weight: 300;
                    margin: 0 8px 0 0;
                    border-radius: 2px;

                    &[type=warning] {
                        width: 107px;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                    }
                }
                
                $types-colors: (
                    'get': #08b7d2,
                    'post': #00b347,
                    'put': #fdd525,
                    'patch': #fdd525,
                    'delete': #e60000,

                    'warning': #fdd525,
                );

                @each $type, $color in $types-colors {
                    &[type=#{ $type }] {
                        background: $color;
                    }
                }
            }

            .api-content {
                width: calc(100% - #{ $menu-width });

                &>:first-child {
                    padding-top: 0;

                    div:first-child {
                        padding-top: 0;
                    }
                }

                a {
                    &[href^=http],
                    &[href^=mailto] {
                        color: #e60000;

                        &:hover { color: #990000; }
                        &:active { color: darken(#990000, 2); }
                    }

                    &[disabled] {
                        cursor: default;
                        pointer-events: none;
                    }

                    &[download] {
                        border-color: #e60000;

                        &:hover { border-color: #990000; }
                        &:active { border-color: darken(#990000, 2); }
                    }

                    &[href^=http] {
                        &:not([download]) {
                            text-decoration: underline;

                            &:hover { text-decoration: none; }
                        }
                    }
                }

                &>div { // [data-section-id]
                    padding: 16px 0;

                    h1, h2, h3, h4, h5 {
                        a {
                            &:before {
                                vertical-align: inherit;
                            }
                        }
                    }

                    &>div {
                        padding: 0;
                        contain: none;
                        overflow: visible;

                        &>* {
                            padding: 0;
                        }

                        &>:nth-child(1) {
                            width: percentage($api-content-width);

                            h2 {
                                font-size: 32px;
                                line-height: 48px;
                                margin-bottom: 24px;
                                padding-bottom: 16px;
                                border-bottom: 3px solid #333;

                                &~div,
                                &~h5,
                                &~caption {
                                    margin: 16px 0 0;
                                }
                                
                                &~div {
                                    a {
                                        color: #0041ff;

                                        &~code {
                                            @include api-tag(#666, 20px);
                                        }
                                    }
                                }

                                &+div {
                                    p {
                                        font-size: 18px;
                                        line-height: 24px;
                                        padding: 4px 0;
                                    }
                                }
                            }

                            h3 {
                                font-size: 24px;
                                line-height: 32px;
                                font-weight: bold;
                                margin: 24px 0 16px;
                                padding: 0;

                                &~div {
                                    &>button {
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: center;
                                        height: 48px;
                                        font-size: 14px;
                                        line-height: 24px;
                                        margin-bottom: 16px;

                                        svg,
                                        &:before {
                                            margin-right: 4px;
                                        }

                                        strong {
                                            font-size: 18px;
                                            font-weight: bold;
                                            margin: 0 12px 0 0;
                                        }

                                        &+div {
                                            padding: 0 0 16px 0;
                                        }
                                    }
                                }
                            }

                            h5, caption {
                                color: #999;
                                font-size: 16px;
                                line-height: 20px;
                                font-weight: 300;
                                margin: 0;
                                border: none;

                                padding: 0 8px 16px 0;
                                border-bottom: 1px solid #999;

                                &:only-child {
                                    padding: 0 8px 0 0;
                                    border: none;
                                }

                                .dropdown {
                                    margin-left: 16px;

                                    .dropdown-selector {
                                        padding-right: 4px;
                                    }
                                }
                            }

                            &:only-child {
                                &>div {
                                    width: 100%;
                                }

                                h1 {
                                    color: #333;
                                    font-size: 32px;
                                    line-height: 48px;
                                    margin: 32px 0 24px;
                                    padding: 0 0 16px 0;
                                    border-bottom: 3px solid #333;
                                }

                                h2 {
                                    &+div {
                                        p {
                                            font-size: 18px;
                                            line-height: 32px;
                                            font-weight: 300;
                                        }
                                    }
                                }

                                code {
                                    font-size: 14px;
                                    @include api-tag(#e60000, 24px);
                                }
                            }
                        }

                        .api-info,
                        .redoc-markdown {
                            font-size: 18px;
                            line-height: 32px;
                            font-weight: 300;

                            &>* {
                                font-size: inherit;
                                line-height: inherit;
                                font-weight: inherit;
                            }
                            
                            ul {
                                @include api-auth-ul(16px, 32px);
                            }
                        }

                        .api-info {
                            padding: 0 30px;
                        }

                        .redoc-markdown {
                            width: 100%;
                        }

                        table.security-details {
                            display: table;
                            border-radius: 2px;
                            margin: 24px 0 0;
                            overflow: hidden;
                            
                            tbody {
                                width: 100%;

                                tr {
                                    td, th {
                                        padding: 16px;
                                        background: rgba(#d2d2d2, .4);
                                        border-color: transparent #fff #fff transparent;
                                        border-width: 1px;

                                        &:last-child {
                                            border-right-color: transparent;
                                        }

                                        &>div {
                                            margin-bottom: 8px;
                                            word-break: break-word;

                                            &:last-child {
                                                margin-bottom: 0;
                                            }
                                        }
                                    }

                                    &:last-child {
                                        td, th {
                                            border-bottom-color: transparent;
                                        }
                                    }
                                }
                            }

                            strong {
                                font-weight: bold;
                            }

                            ul {
                                @include api-auth-ul(14px, 24px);

                                li {
                                    p,
                                    span {
                                        font-size: inherit;
                                        line-height: inherit;
                                    }
                                }

                                code {
                                    font-size: 14px;
                                    @include api-tag(#e60000, 24px);
                                }
                            }
                        }
                        
                        table:not(.security-details) {
                            $path-color: #ccc;

                            tbody {
                                tr {
                                    td {
                                        padding: 16px 8px 16px 0;

                                        * {
                                            font-size: 14px;
                                            line-height: 24px;
                                        }

                                        &>div {
                                            &>* {
                                                margin-top: 8px;
                                            }

                                            &>:first-child {
                                                margin-top: 0;

                                                span:not([class]) {
                                                    &>span {
                                                        @include api-tag(#0041ff, 24px);
                                                    }
                                                }
                                            }

                                            &>:not(:first-child) {
                                                &>span {
                                                    &:first-child {
                                                        &~span {
                                                            @include api-tag(#666, 24px);
                                                        }
                                                    }
                                                }
                                            }

                                            span {
                                                color: #666;
                                                vertical-align: baseline;
                                            }
                                        }

                                        &[kind],
                                        &[colspan="2"] {
                                            border-left-color: $path-color;
                                        }

                                        &[kind] {
                                            color: #333;
                                            font-size: 14px;
                                            line-height: 16px;

                                            &>* {
                                                font-size: inherit;
                                                line-height: inherit;
                                            }

                                            span {
                                                margin-right: 8px;

                                                &:before,
                                                &:after {
                                                    background: $path-color;
                                                }
                                            }

                                            button {
                                                height: 16px;
                                                font-weight: normal;

                                                svg {
                                                    width: 16px;
                                                    height: 16px;
                                                    vertical-align: bottom;
                                                    margin-left: 2px;

                                                    polygon {
                                                        fill: #333;
                                                    }
                                                }
                                            }

                                            div {
                                                margin-top: 8px;
                                            }
                                        }

                                        &[colspan="2"] {
                                            padding: 0;
                                            
                                            &>div {
                                                margin: 0 0 0 16px;
                                                padding: 16px;
                                                background: rgba(#d2d2d2, .4);

                                                table {
                                                    margin-bottom: 16px;
                                                }

                                                div {
                                                    line-height: 16px;
                                                }
                                            }

                                            [colspan="2"] {
                                                &>div {
                                                    background: rgba(#fff, .4);
                                                }
                                            }
                                        }

                                        .dropdown {
                                            padding: 0 0 0 8px;
                                            border: 1px solid #ccc;
                                            border-radius: 2px;

                                            .dropdown-selector {
                                                padding: 0;

                                                .dropdown-arrow {
                                                    margin-right: 20px;
                                                }
                                            }

                                            .dropdown-selector-content {
                                                .dropdown-option {
                                                    padding: 0 4px 0 9px;
                                                }
                                            }
                                        }
                                    }

                                    &.last {
                                        &+tr {
                                            td {
                                                &[colspan="2"] {
                                                    border-left-color: transparent;
                                                }
                                            }
                                        }
                                    }

                                    &:not(:only-child) {
                                        &:first-child {
                                            td {
                                                &[kind] {
                                                    background-image: linear-gradient(transparent 0%, transparent 27px, $path-color 27px, $path-color 100%);
                                                }
                                            }
                                        }

                                        &.last {
                                            td {
                                                &[kind] {
                                                    background-image: linear-gradient($path-color 0%, $path-color 27px, transparent 27px, transparent 100%);
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &+h5 {
                                margin-top: 16px;
                            }
                        }

                        .dropdown {
                            &:hover {
                                box-shadow: none;
                            }

                            .dropdown-selector {
                                margin: 0;

                                .dropdown-arrow {
                                    border: none;

                                    @include icon-before($icon-chevron-bottom);

                                    svg {
                                        display: none;
                                    }
                                }
                            }

                            .dropdown-selector-content {
                                padding: 0;

                                .dropdown-option {
                                    font-size: 14px;
                                    line-height: 22px;
                                    margin: 1px 0;
                                    padding: 0 8px;
                                    background: #fff;

                                    &:hover {
                                        background: rgba(#d2d2d2, .2);
                                    }

                                    &[aria-selected=true] {
                                        background: rgba(#d2d2d2, .4);
                                    }
                                }
                            }
                        }

                        &>:nth-child(2) {
                            width: percentage($api-samples-width);
                            background: $bg-samples;

                            &>:nth-child(1) {
                                margin: 0;

                                &:only-child {
                                    margin-bottom: 15px;
                                }

                                button {
                                    padding: 6px 32px 6px 16px;
                                    border: none;
                                    border-radius: 2px;
                                    background: #333;

                                    span {
                                        &[type] {
                                            margin: 0 8px 0 0;
                                        }

                                        &:not([type]) {
                                            font-size: 16px;
                                            line-height: 22px;
                                            margin: 0 0 0 8px;
                                        }
                                    }

                                    svg {
                                        margin-right: -20px !important;
                                    }

                                    &+[aria-hidden] {
                                        margin: 8px 0 0;
                                        padding: 4px 0 0;
                                        border-radius: 2px;
                                        background: #fff;
                                        
                                        &>div {
                                            padding: 4px 8px 12px;

                                            p {
                                                font-size: 14px;
                                                line-height: 18px;
                                            }

                                            [role=button] {
                                                margin: 5px 0 0;
                                                border: 1px solid #cfcfcf;
                                                border-radius: 2px;
                                                background: rgba(210, 210, 210, 0.4);

                                                &>div {
                                                    color: #666;
                                                    font-size: 14px;
                                                    line-height: 24px;
                                                    padding: 0 8px;
                                                    border: none;
                                                    background: transparent;

                                                    span {
                                                        color: inherit;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &>:nth-child(2) {
                                margin-top: 24px;

                                h3 {
                                    font-size: 24px;
                                    line-height: 32px;
                                    font-weight: bold;
                                }

                                &>div {
                                    [role=tablist] {
                                        margin: 8px -4px 0;

                                        li {
                                            width: 105px;
                                            height: 32px;
                                            font-size: 16px;
                                            line-height: 30px;
                                            font-weight: normal;
                                            margin: 0 4px 8px 4px;
                                            padding: 0;
                                            background: #333;
                                            border-color: #333;

                                            &[aria-selected=true] {
                                                color: #00b347;
                                                background: #fff;
                                                border-color: #fff;
                                            }
                                        }
                                    }

                                    [role=tabpanel] {
                                        background: #0c0d0f;

                                        &>div {
                                            padding: 16px 16px 16px;

                                            @mixin api-samples-dropdown-tooltip() {
                                                color: rgba(#d2d2d2, .4);
                                                font-size: 12px;
                                                font-weight: normal;
                                            }

                                            &>div {
                                                &:nth-child(1) {
                                                    &>span {
                                                        &:first-child {
                                                            @include api-samples-dropdown-tooltip();
                                                        }
                                                    }

                                                    .dropdown {
                                                        margin-top: 8px;
                                                    }
                                                }

                                                &:nth-child(2) {
                                                    color: #e60000;
                                                    font-size: 14px;
                                                }
                                                
                                                &>div {
                                                    &>span {
                                                        &:first-child {
                                                            @include api-samples-dropdown-tooltip();
                                                        }
                                                    }

                                                    .dropdown {
                                                        margin-top: 24px;
                                                    }
                                                }

                                                button {
                                                    color: #fff;
                                                    margin: 0 0 16px 16px;
                                                    padding: 4px 12px;

                                                    &:first-child { margin-left: 0; }
                                                }
                                            }
                                        }

                                        .dropdown {
                                            margin: 8px 0 16px;
                                            padding: 8px 32px 8px 16px;
                                            background: #242424;

                                            .dropdown-selector {
                                                .dropdown-selector-value {
                                                    font-size: 16px;
                                                    line-height: 22px;
                                                }

                                                .dropdown-arrow {
                                                    &:before {
                                                        color: #fff;
                                                        font-size: 26px;
                                                    }
                                                }
                                            }
                                        }
                                        
                                        .redoc-json {
                                            color: #fff;
                                            font-size: 14px;
                                            font-weight: 300;

                                            .token {
                                                &.property,
                                                &.tag,
                                                &.number,
                                                &.constant,
                                                &.symbol {
                                                    color: #08b7d2
                                                }

                                                &.selector,
                                                &.attr-name,
                                                &.string,
                                                &.char,
                                                &.builtin,
                                                &.inserted {
                                                    color: #00b347;
                                                }

                                                &.property,
                                                &.punctuation {
                                                    color: #fff;
                                                }

                                                &.punctuation {
                                                    opacity: 1;
                                                }
                                            }
                                        }

                                        pre {
                                            color: #fff;
                                            font-size: 14px;
                                            line-height: 24px;

                                            .token {
                                                &.operator,
                                                &.entity,
                                                &.url,
                                                &.variable {
                                                    color: #fdd525;
                                                }

                                                &.property,
                                                &.tag,
                                                &.number,
                                                &.constant,
                                                &.symbol {
                                                    color: #08b7d2;
                                                }

                                                &.selector,
                                                &.attr-name,
                                                &.string,
                                                &.char,
                                                &.builtin,
                                                &.inserted {
                                                    color: #00b347;
                                                }

                                                &.comment,
                                                &.prolog,
                                                &.doctype,
                                                &.cdata {
                                                    color: #6a9955;
                                                }

                                                &.atrule,
                                                &.attr-value,
                                                &.keyword {
                                                    color: #b141ad;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    [data-section-id] {
                        &>div {
                            &>div {
                                padding: 16px 0 0;
                            }
                        }
                    }

                    &[data-section-id^=tag\/] {
                        padding-bottom: 32px;

                        &>div {
                            &>:nth-child(1) {
                                &:only-child {
                                    h1 {
                                        font-weight: bold;
                                        margin-bottom: 16px;
                                        padding: 0;
                                        border: none;
                                    }
                                }
                            }
                        }
                    }
                }

                &>[data-section-id] {
                    &>div {
                        &>:nth-child(1) {
                            padding: 0 30px;
                        }

                        &>:nth-child(2) {
                            padding: 30px 30px 15px;
                        }
                    }
                }
            }

            &>:last-child {
                width: calc((100% - #{ $menu-width }) * #{ $api-samples-width });
                background: $bg-samples;
            }
        }
    }
}

@media print, screen and (max-width: 75rem) {
    #page-api {
        #redoc-container {
            .redoc-wrap {
                .api-content {
                    &>div { // [data-section-id]
                        padding-bottom: 0;

                        &>div {
                            &>:nth-child(1),
                            &>:nth-child(2) {
                                width: 100%;
                            }

                            &>:nth-child(1) {
                                h2 {
                                    &~div,
                                    &~h5,
                                    &~caption {
                                        margin: 16px 0 0;
                                    }
                                }

                                // &:only-child {
                                //     &>div {
                                //         // padding: 0;
                                //     }
                                // }
                            }

                            &>:nth-child(2) {
                                padding-bottom: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media print, screen and (max-width: 50rem) {
    #page-api {
        #redoc-container {
            .redoc-wrap {
                .menu-content {
                    width: 100%;
                    
                    [role=search] {
                        margin: 0 15px;

                        input {
                            &+div {
                                margin: 15px -15px 0;
                            }
                        }
                    }

                    ul[role=navigation],
                    [data-role="search:results"] {
                        padding: 8px 0;
                        
                        li {
                            font-size: 16px;
                            
                            label[role=menuitem] {
                                padding: 5px 15px;
                                line-height: 24px;

                                &[type] {
                                    padding: 5px 15px;
                                }

                                &[type=group] {
                                    margin-top: 8px;
                                }

                                &[type=tag] {
                                    margin: 0;
                                }

                                span {
                                    &[type] {
                                        margin: 0 6px 0 0;
                                    }
                                }
                            }

                            ul {
                                li {
                                    ul {
                                        li {
                                            label[role=menuitem] {
                                                padding: 4px 15px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &+div {
                            padding: 0;

                            a {
                                padding: 5px 15px;
                            }
                        }
                    }
                }

                span {
                    &[type] {
                        width: 56px;
                        height: 24px;
                    }
                }
                
                .api-content {
                    width: 100%;

                    &>div { // [data-section-id]
                        h1, h2, h3, h4, h5 {
                            a {
                                width: 15px;
                                margin-left: -15px;

                                &:before {
                                    width: 12px;
                                    height: 12px;
                                    margin-left: 1px;
                                }
                            }
                        }

                        &>div {
                            &>:nth-child(1) {
                                h2 {
                                    font-size: 24px;
                                    line-height: 32px;
                                    margin-bottom: 16px;
                                }

                                &:only-child {
                                    h1 {
                                        font-size: 24px;
                                        line-height: 32px;
                                        margin: 16px 0;
                                    }
                                }
                            }

                            table.security-details {
                                tbody {
                                    tr {
                                        td, th {
                                            padding: 8px;
                                        }
                                    }
                                }
                            }
                            
                            .api-info,
                            .redoc-markdown {
                                font-size: 16px;
                                line-height: 24px;
                            }

                            .api-info {
                                padding: 0 15px;
                            }
                        }

                        &[data-section-id^=tag\/] {
                            padding: 32px 0;
                        }
                    }

                    &>[data-section-id] {
                        &>div {
                            &>:nth-child(1) {
                                padding: 0 15px;
                            }

                            &>:nth-child(2) {
                                padding: 30px 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>