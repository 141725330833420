<template>
    <div class="api-footer">
        <div class="api-content">
            <div class="terms">
                <div class="copiright">© Copyright © {{ years }} Vodafone New Zealand Ltd.</div>
                <hr>
                <a href="#">Terms and conditions</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            created_year: 2019,
        }
    },

    computed: {
        years() {
            const current_year = (new Date()).getFullYear()

            return current_year > this.created_year ? `${ this.created_year } - ${ current_year }` : this.created_year
        },
    },
}
</script>

<style lang="scss">
.api-footer {
    padding: 40px 24px;
    border-top: 1px solid #ccc;
    background: #f4f4f4;

    z-index: $z-index-loader + 1;

    .api-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 1580px;
        max-width: 100%;
        margin: 0 auto;

        .terms {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #999;
            font-size: 16px;
            line-height: 24px;
            font-weight: 300;

            &>* {
                margin: 0 24px 0 0;

                &:last-child { margin: 0; }
            }

            hr {
                height: 24px;
                border-color: #999;
                border-width: 0 1px 0 0;
            }

            a {
                color: inherit;
                white-space: nowrap;

                &:hover { color: #e60000; }
                &:active { color: #990000; }
            }
        }
    }
}

@media print, screen and (max-width: 75rem) {}

@media screen and (max-width: 50rem) {
    .api-footer {
        padding: 24px 15px;

        .api-content {
            .terms {
                flex-direction: column;
                align-items: flex-start;

                &>* {
                    margin: 0 0 12px 0;
                }

                hr {
                    width: 100%;
                    height: 0;
                    border-width: 1px 0 0 0;
                }
            }
        }
    }
}
</style>