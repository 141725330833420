<template>
    <div class="api-header">
        <div class="api-content">
            <router-link class="logo" :to="{ name: 'main-dashboard' }">
                <img class="wide" height="40px" src="/splogos/1.svg">
                <img class="short" height="40px" src="/splogos/thumbnail-1.svg">
            </router-link>

            <h1>API</h1>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            processing: false,
        }
    },
}
</script>

<style lang="scss">
.api-header {
    padding: 24px 24px 52px;

    z-index: $z-index-loader + 1;

    .api-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1580px;
        max-width: 100%;
        margin: 0 auto;

        .logo {
            display: flex;
            margin-right: 40px;

            .short {
                display: none;
            }
        }

        h1 {
            flex-grow: 1;
            font-size: 20px;
            line-height: 48px;
        }
    }
}

@media print, screen and (max-width: 75rem) {}

@media screen and (max-width: 50rem) {
    .api-header {
        padding: 24px 15px 36px;

        .api-content {
            .logo {
                margin-right: 16px;

                .wide {
                    display: none;
                }
                .short {
                    display: initial;
                }
            }
        }
    }
}
</style>